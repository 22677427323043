// 处理行政区划数据最后一级没有数据出现空白面板
export function split(data) {
  data.forEach((ele) => {
    // ele.value = ele.id; //创建一个value字段
    if (ele.children.length === 0) {
      //如果children为空，则删除children
      delete ele.children;
    } else {
      //children不为空表示里面仍然有子节点，继续遍历
      split(ele.children);
    }
  })
  return data;
}
